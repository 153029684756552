import { useCategoriesQuery } from '@services/api/hooks/useCategoriesQuery';
import { useAttributeConfigsQuery } from '@services/hooks/attributes/useAttributeConfigsQuery';
import { useProductAttributesQuery } from '@services/hooks/attributes/useProductAttributesQuery';
import { usePriceListsQuery } from '@services/hooks/common/usePriceListsQuery';
import { useStockListsQuery } from '@services/hooks/common/useStockListsQuery';
import { useDataSourcesQuery } from '@services/hooks/datasources/useDataSourcesQuery';

import useAttributeSetId from '../useAttributeSetId';

export function useQueries({ categoryId }) {
  const { productAttributes } = useProductAttributesQuery();
  const { categories } = useCategoriesQuery();
  const attributeSetId = useAttributeSetId({ categories, categoryId });
  const { attributeConfigs } = useAttributeConfigsQuery({
    attributeSetId,
    queryOptions: { enabled: Boolean(attributeSetId) },
  });
  const { priceLists } = usePriceListsQuery();
  const { stockLists } = useStockListsQuery();
  const { dataSourcesResult: dataSources, isDataSourcesLoading } = useDataSourcesQuery();

  return {
    attributeConfigs,
    categories,
    dataSources,
    priceLists,
    productAttributes,
    attributeSetId,
    stockLists,
    isDataSourcesLoading,
  };
}
