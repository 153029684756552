import { OfferApprovalStatus } from '@constants/commontypes';
import { useDataSourceDetailQuery } from '@services/api/hooks';
import { useCategoriesQuery } from '@services/api/hooks/useCategoriesQuery';
import { useMasterProductPricesQuery } from '@services/api/hooks/useMasterProductPricesQuery';
import { useProductLowestPriceQuery } from '@services/api/hooks/useProductLowestPriceQuery';
import { useRejectionReasonsQuery } from '@services/api/hooks/useRejectionReasonsQuery';
import { useRejectionsQuery } from '@services/api/hooks/useRejectionsQuery';
import { useAttributeConfigsQuery } from '@services/hooks/attributes/useAttributeConfigsQuery';
import { useProductAttributesQuery } from '@services/hooks/attributes/useProductAttributesQuery';
import { usePriceListsQuery } from '@services/hooks/common/usePriceListsQuery';
import { useStockListsQuery } from '@services/hooks/common/useStockListsQuery';
import { useDataSourcesQuery } from '@services/hooks/datasources/useDataSourcesQuery';
import { usePreOfferQuery } from '@services/hooks/preOffers/usePreOfferQuery';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { forbiddenKeys } from '../../common';
import { useDynamicFormFields } from '../useDynamicFormFields';

export function useQueries({ t }) {
  const { offerId } = useParams();
  const { preOffer, isPreOfferLoading, preOfferError } = usePreOfferQuery({ preOfferId: offerId });
  const { rejections } = useRejectionsQuery({
    params: {
      parent: preOffer?.id,
    },
    queryOptions: {
      enabled: preOffer?.approval_status === OfferApprovalStatus.REJECTED,
    },
  });
  const rejection = first(rejections);
  const { rejectionReasons } = useRejectionReasonsQuery({
    params: {
      id: rejection?.reason,
    },
    queryOptions: {
      enabled: Boolean(rejection?.reason),
    },
  });
  const reason = first(rejectionReasons);
  const attributeSetId = preOffer?.attribute_set;
  const { productAttributes, productAttributesError } = useProductAttributesQuery();
  const { categories } = useCategoriesQuery();
  const { attributeConfigs, attributeConfigsError } = useAttributeConfigsQuery({
    attributeSetId,
    queryOptions: { enabled: Boolean(attributeSetId) },
  });
  const { priceLists } = usePriceListsQuery();
  const { stockLists } = useStockListsQuery();
  const { dataSourcesResult: dataSources } = useDataSourcesQuery();

  const { dataSourceDetail, isFetchingDataSourceDetail } = useDataSourceDetailQuery({
    dataSourceId: preOffer?.datasource,
    queryOptions: {
      enabled: Boolean(preOffer?.datasource),
    },
  });

  const { masterProductPrices, isMasterProductPricesLoading, masterProductPricesError } =
    useMasterProductPricesQuery({
      params: {
        limit: 1,
        product__in: preOffer?.product,
      },
      queryOptions: {
        enabled: !isEmpty(preOffer?.product),
      },
    });

  const { productLowestPrice, isProductLowestPriceLoading, productLowestPriceError } =
    useProductLowestPriceQuery({
      productId: preOffer?.product,
      queryOptions: {
        enabled: !isEmpty(preOffer?.product),
      },
    });

  const { variantListAttributeFields, variantDynamicFields } = useDynamicFormFields({
    attributeConfigs,
    productAttributes,
    forbiddenKeys,
    t,
  });
  const dynamicFields = useMemo(() => {
    if (isEmpty(variantDynamicFields) || isEmpty(variantListAttributeFields)) {
      return [];
    }
    return [variantDynamicFields, variantListAttributeFields];
  }, [variantListAttributeFields, variantDynamicFields]);

  return {
    preOffer: {
      ...preOffer,
      rejection,
      reason,
    },
    isPreOfferLoading,
    preOfferError,
    attributeConfigs,
    attributeConfigsError,
    categories,
    dataSourceDetail,
    isFetchingDataSourceDetail,
    dataSources,
    priceLists,
    productAttributes,
    productAttributesError,
    attributeSetId,
    stockLists,
    buyboxPrice: first(masterProductPrices)?.price,
    isBuyBoxPriceLoading: !isEmpty(preOffer?.product) && isMasterProductPricesLoading,
    buyboxPriceError: !isEmpty(preOffer?.product) && masterProductPricesError,
    lowestPrice: productLowestPrice?.price,
    isLowestPriceLoading: !isEmpty(preOffer?.product) && isProductLowestPriceLoading,
    lowestPriceError: !isEmpty(preOffer?.product) && productLowestPriceError,
    dynamicFields,
  };
}
