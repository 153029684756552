import './style.scss';

import AddProductsFromCatalogImage from '@assets/images/add-products-from-catalog.jpg';
import AkinonButton from '@components/AkinonButton';
import Intrinsic from '@components/Intrinsic';
import NavigationList from '@components/Navigation/List';
import { RouteUrls } from '@constants/routeUrls';
import { Color } from '@constants/theme';
import { useIsLiveAppVersion } from '@hooks/index';
import useAppNavigate from '@root/hooks/useAppNavigate';
import { useUserRole } from '@utils/hooks/useUserRole';
import { Col, Form, Image, Input, Row, Typography } from 'antd';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { getNavigationItems } from './common';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { usePageMeta } from './hooks/usePageMeta';

const { Text, Title } = Typography;

const AddProductsFromCatalog = () => {
  const { t } = useTranslation('ProductsAndCategories');
  const navigate = useAppNavigate();
  const [form] = Form.useForm();
  const userRole = useUserRole();
  const isLiveAppVersion = useIsLiveAppVersion();

  useBreadcrumbs();
  usePageMeta();

  const navigationItems = getNavigationItems({ t, userRole, isLiveAppVersion });

  const handleSubmit = (formValues) => {
    const { search } = formValues;
    navigate(RouteUrls.productsAndCategories.products.productPool, {
      state: { searchQuery: search },
    });
  };

  return (
    <section className="add-products-from-catalog">
      <div className="bg-white rounded-md mb-10 flex items-center justify-center">
        <Row
          className="flex flex-col-reverse justify-center items-center w-3/4 md:join-[flex-row,py-20]"
          gutter={[16, 16]}
        >
          <Col span={24} sm={12}>
            <div className="w-full flex flex-col justify-between gap-8 h-full">
              <Title level={4}>{t('add.from.marketplace.catalog')}</Title>
              <Text>
                <Trans i18nKey={t(`add_products_from_catalog_info__for_${userRole}`)} />
              </Text>
              <Form onFinish={handleSubmit} form={form}>
                <Form.Item className="add-products-from-catalog__search" name="search">
                  <Input
                    className="add-products-from-catalog__search-input"
                    placeholder={t('add.products.from.catalog.search.placeholder')}
                    prefix={<Intrinsic className="icon-search" />}
                  />
                </Form.Item>
                <Form.Item>
                  <AkinonButton htmlType="submit" type="primary" block>
                    {t('search').toUpperCase()}
                  </AkinonButton>
                </Form.Item>
              </Form>
            </div>
          </Col>
          <Col span={24} sm={12}>
            <Image
              preview={false}
              src={AddProductsFromCatalogImage}
              alt={t('add.from.marketplace.catalog')}
            />
          </Col>
        </Row>
      </div>
      <NavigationList items={navigationItems} cardColor={Color.DODGER_BLUE} />
    </section>
  );
};

export default AddProductsFromCatalog;
