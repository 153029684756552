import './index.scss';

import AkinonButton from '@components/AkinonButton';
import AkinonFormItem from '@components/AkinonFormItem';
import AkinonSpin from '@components/AkinonSpin';
import AuthorizedComponent from '@components/AuthorizedComponent';
import Show from '@components/Show';
import Box from '@components/utility/box';
import { UserRole } from '@constants/auth';
import { zodResolver } from '@hookform/resolvers/zod';
import { usePostPreOffersMutation } from '@services/hooks/preOffers/usePostPreOffersMutation';
import useStore from '@zustand-store/index';
import { resetPreOfferSelector } from '@zustand-store/selectors/preOffer';
import { Space } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useUnmount } from 'react-use';

import { forbiddenKeys } from './common';
import BasicProductInfoForm from './components/BasicProductInfoForm';
import {
  BasicProductInfoFormItemKey,
  getBasicProductInfoFormSchema,
} from './components/BasicProductInfoForm/common';
import DescriptionForm from './components/DescriptionForm';
import { getDescriptionFormSchema } from './components/DescriptionForm/common';
import ProductFeaturesForm from './components/ProductFeaturesForm';
import { getProductFeaturesFormSchema } from './components/ProductFeaturesForm/common';
import ProductsToBeAddedTableForm from './components/ProductsToBeAddedTableForm';
import { getProductsToBeAddedTableFormSchema } from './components/ProductsToBeAddedTableForm/common';
import VariantsForm from './components/VariantsForm';
import { getVariantsFormSchema } from './components/VariantsForm/common';
import { useQueries } from './hooks/api/useQueries';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { useDynamicFormFields } from './hooks/useDynamicFormFields';
import { useOnSubmit } from './hooks/useOnSubmit';
import { usePageMeta } from './hooks/usePageMeta';

const ProductForm = () => {
  const { t } = useTranslation('ProductsAndCategories');

  const [isOfferProductsReadyToBeDisplayed, setIsOfferProductsReadyToBeDisplayed] = useState(false);

  const basicProductInfoFormSchema = getBasicProductInfoFormSchema();
  const basicProductInfoForm = useForm({
    mode: 'onChange',
    resolver: zodResolver(basicProductInfoFormSchema),
    defaultValues: {
      basicProductInfoForm: {
        restrict_for_others: true,
      },
    },
  });

  const descriptionFormSchema = getDescriptionFormSchema({ t });
  const descriptionForm = useForm({
    mode: 'onChange',
    resolver: zodResolver(descriptionFormSchema),
  });

  const categoryId = useWatch({
    control: basicProductInfoForm.control,
    name: BasicProductInfoFormItemKey.CATEGORY,
  });
  const { postPreOffers, isPostPreOffersMutating } = usePostPreOffersMutation();
  const resetPreOffer = useStore(resetPreOfferSelector);
  const {
    attributeConfigs,
    attributeSetId,
    dataSources,
    priceLists,
    productAttributes,
    stockLists,
    isDataSourcesLoading,
  } = useQueries({
    categoryId,
  });

  const {
    variantListAttributeFields,
    variantDynamicFields,
    isVariantListAttributeFieldsLoading,
    isVariantDynamicFieldsLoading,
  } = useDynamicFormFields({
    attributeConfigs,
    productAttributes,
    forbiddenKeys,
    t,
  });

  const productFeaturesFormSchema = useMemo(() => {
    if (isVariantListAttributeFieldsLoading) return {};
    return getProductFeaturesFormSchema({
      variantListAttributeFields,
      isOfferProductsReadyToBeDisplayed,
    });
  }, [
    variantListAttributeFields,
    isOfferProductsReadyToBeDisplayed,
    isVariantListAttributeFieldsLoading,
  ]);
  const productFeaturesForm = useForm({
    mode: 'onChange',
    resolver: zodResolver(productFeaturesFormSchema),
  });

  const variantsFormSchema = useMemo(() => {
    if (isVariantDynamicFieldsLoading) return {};
    return getVariantsFormSchema({
      variantDynamicFields,
    });
  }, [variantDynamicFields]);
  const variantsForm = useForm({
    mode: 'onChange',
    resolver: zodResolver(variantsFormSchema),
    defaultValues: {},
  });

  const variants = useWatch({
    control: variantsForm.control,
    name: 'variantsForm',
    defaultValue: {},
  });

  const productFeatures = useWatch({
    control: productFeaturesForm.control,
    name: 'productFeaturesForm',
    defaultValue: {},
  });

  const productsToBeAddedTableFormSchema = useMemo(() => {
    return getProductsToBeAddedTableFormSchema({
      variantListAttributeFields,
      variantDynamicFields,
      t,
    });
  }, [variantListAttributeFields, variantDynamicFields, t]);

  const productsToBeAddedTableForm = useForm({
    mode: 'onChange',
    resolver: zodResolver(productsToBeAddedTableFormSchema),
  });

  const onSubmit = useOnSubmit({
    basicProductInfoForm,
    productFeaturesForm,
    productsToBeAddedTableForm,
    descriptionForm,
    priceLists,
    stockLists,
    dataSources,
    attributeSetId,
    variantListAttributeFields,
    postPreOffers,
  });

  useUnmount(() => {
    resetPreOffer();
  });

  useEffect(() => {
    variantsForm.reset();
    productsToBeAddedTableForm.reset();
    productFeaturesForm.reset();
    if (isOfferProductsReadyToBeDisplayed) {
      setIsOfferProductsReadyToBeDisplayed(false);
    }
  }, [categoryId]);

  useBreadcrumbs();
  usePageMeta();

  return (
    <section className="single-product-form">
      <BasicProductInfoForm
        basicProductInfoForm={basicProductInfoForm}
        schema={basicProductInfoFormSchema}
        dataSources={dataSources}
        isDataSourcesLoading={isDataSourcesLoading}
      />
      <Show when={attributeSetId}>
        <AkinonSpin
          tip={t('variants.loading')}
          size="large"
          spinning={isVariantDynamicFieldsLoading || isVariantListAttributeFieldsLoading}
        >
          <Show when={!isEmpty(variantListAttributeFields)}>
            <ProductFeaturesForm
              productFeaturesForm={productFeaturesForm}
              basicProductInfoForm={basicProductInfoForm}
              isOfferProductsReadyToBeDisplayed={isOfferProductsReadyToBeDisplayed}
              variantListAttributeFields={variantListAttributeFields}
              isVariantDynamicFieldsEmpty={isEmpty(variantDynamicFields)}
              setIsOfferProductsReadyToBeDisplayed={setIsOfferProductsReadyToBeDisplayed}
            />
          </Show>
          <Show when={!isEmpty(variantDynamicFields)}>
            <VariantsForm
              variantDynamicFields={variantDynamicFields}
              basicProductInfoForm={basicProductInfoForm}
              isOfferProductsReadyToBeDisplayed={isOfferProductsReadyToBeDisplayed}
              setIsOfferProductsReadyToBeDisplayed={setIsOfferProductsReadyToBeDisplayed}
              variantsForm={variantsForm}
              productFeaturesForm={productFeaturesForm}
            />
          </Show>
        </AkinonSpin>
        <Show when={isOfferProductsReadyToBeDisplayed}>
          <Space style={{ width: '100%' }} direction="vertical" size="large">
            <ProductsToBeAddedTableForm
              variantDynamicFields={variantDynamicFields}
              variantListAttributeFields={variantListAttributeFields}
              form={productsToBeAddedTableForm}
              variants={variants}
              productFeatures={productFeatures}
              productFeaturesForm={productFeaturesForm}
            />
            <DescriptionForm descriptionForm={descriptionForm} />
            <Box className="box-primary form-box">
              <AkinonFormItem wrapperCol={{ span: 24 }}>
                <AuthorizedComponent userRole={UserRole.SELLER}>
                  <AkinonButton
                    className="submit-form__approve-button"
                    loading={isPostPreOffersMutating}
                    htmlType="button"
                    onClick={onSubmit}
                    type="primary"
                  >
                    {t('submit_for_approval')}
                  </AkinonButton>
                </AuthorizedComponent>
              </AkinonFormItem>
            </Box>
          </Space>
        </Show>
      </Show>
    </section>
  );
};

export default ProductForm;
