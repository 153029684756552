import Modal from '@components/Modal/Modal';
import { OfferApprovalStatus } from '@constants/commontypes';
import { RouteUrls } from '@constants/routeUrls';
import useAppNavigate from '@root/hooks/useAppNavigate';
import useStore from '@zustand-store/index';
import { selectedRelatedProductSelector } from '@zustand-store/selectors/preOffer';
import first from 'lodash/first';
import get from 'lodash/get';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { FormKey } from '../common';
import ApproveAndChangeSKUModalContent from '../components/ApproveAndChangeSKUModalContent';

export function useOnSubmit({
  preOffer,
  priceLists,
  stockLists,
  dataSources,
  patchPreOffers,
  submitPreOffer,
  approvePreOffer,
  setIsRejectModalVisible,
  isSuperUser,
  variantListAttributeFields,
  canEditOffer,
}) {
  const { t } = useTranslation('ProductsAndCategories');
  const navigate = useAppNavigate();
  const modalRef = useRef();

  const selectedRelatedProduct = useStore(selectedRelatedProductSelector);

  const variantsListAttributeKeys = variantListAttributeFields?.map(({ key }) => key);
  const preOfferApprovalStatus = preOffer?.approval_status;

  const onSubmit = ({ formValues, isRejectAction = false, isSubmitAction = false }) => {
    const pricesKeys = [
      FormKey.CURRENCY_TYPE,
      FormKey.PRICE,
      FormKey.RETAIL_PRICE,
      FormKey.TAX_RATE,
    ];
    const stocksKeys = [FormKey.STOCK];
    const priceListMasterId = priceLists.find(({ is_master }) => is_master)?.id;
    const stockListMasterId = stockLists.find(({ is_master }) => is_master)?.id;
    const sellerDescription = get(formValues, FormKey.SELLER_DESCRIPTION);
    const commonRequestBody = {
      attribute_set: preOffer?.attribute_set,
      datasource: first(dataSources)?.id,
      description: sellerDescription,
      restrict_for_others: get(formValues, FormKey.RESTRICT_FOR_OTHERS || null),
    };
    const requestBodyObject = {
      ...commonRequestBody,
      sku: get(formValues, FormKey.SKU),
      ...(isSuperUser && pick(formValues, [FormKey.NAME, FormKey.CATEGORY, FormKey.BASE_CODE])),
      attributes: {
        ...variantsListAttributeKeys?.reduce?.(
          (preOfferAttributes, attributeKey) => ({
            ...preOfferAttributes,
            [attributeKey]: get(formValues, attributeKey),
          }),
          {}
        ),
        SELLER_BARCODE: get(formValues, FormKey.SELLER_BARCODE),
        SELLER_DESCRIPTION: sellerDescription,
        SELLER_BASECODE: preOffer?.base_code,
      },
      ...(isSuperUser || !canEditOffer
        ? undefined
        : {
            prices: [
              {
                price_list: priceListMasterId,
                ...pick(formValues, pricesKeys),
              },
            ],
            stocks: [
              {
                stock_list: stockListMasterId,
                ...pick(formValues, stocksKeys),
              },
            ],
          }),

      product: isSuperUser ? selectedRelatedProduct?.id : preOffer?.product,
    };

    const requestConfig = {
      successMessage: t('transaction_success'),
      successDescription: t('pending_offers_detail_message.success'),
      errorMessage: t('transaction_failed'),
      errorDescription: t('pending_offers_detail_message.error'),
    };

    patchPreOffers(
      {
        preOfferId: preOffer?.id,
        requestBody: requestBodyObject,
        requestConfig:
          preOfferApprovalStatus !== OfferApprovalStatus.PENDING_APPROVAL &&
          preOfferApprovalStatus !== OfferApprovalStatus.APPROVAL_REQUIRED &&
          !isSubmitAction
            ? requestConfig
            : omit(['successMessage', 'successDescription'], requestConfig),
      },
      {
        onSuccess() {
          if (isSuperUser) {
            const requestBody = selectedRelatedProduct
              ? { product: selectedRelatedProduct.id }
              : {};
            if (isRejectAction) {
              setIsRejectModalVisible(true);
            } else {
              if (preOfferApprovalStatus === OfferApprovalStatus.PENDING_APPROVAL) {
                const onApprove = ({ sku } = {}) => {
                  if (sku) {
                    requestBody[FormKey.SKU] = sku;
                  }
                  approvePreOffer(
                    {
                      preOfferId: preOffer?.id,
                      requestBody,
                      requestConfig,
                    },
                    {
                      onSuccess() {
                        navigate(RouteUrls.productsAndCategories.offerList.pendingApproval);
                      },
                    }
                  );
                };

                if (!requestBody?.product) {
                  const modal = Modal.confirm({
                    icon: null,
                    closable: false,
                    maskClosable: false,
                    keyboard: false,
                    className: 'change-sku-modal',
                    title: <h4 className="change-sku-modal__title">{t('bulk_approve_offer')}</h4>,
                    width: 800,
                    cancelButtonProps: {
                      hidden: true,
                    },
                    okButtonProps: {
                      hidden: true,
                    },
                    content: (
                      <ApproveAndChangeSKUModalContent
                        initialSKU={get(formValues, FormKey.SKU)}
                        onApprove={onApprove}
                        onClose={() => {
                          modalRef.current?.destroy();
                        }}
                      />
                    ),
                  });
                  modalRef.current = modal;
                } else {
                  onApprove();
                }
              } else if (preOfferApprovalStatus === OfferApprovalStatus.APPROVAL_REQUIRED) {
                submitPreOffer(
                  {
                    preOfferId: preOffer?.id,
                    requestBody,
                    requestConfig: omit(['successMessage', 'successDescription'], requestConfig),
                  },
                  {
                    onSuccess() {
                      approvePreOffer(
                        {
                          preOfferId: preOffer?.id,
                          requestBody,
                          requestConfig,
                        },
                        {
                          onSuccess() {
                            navigate(RouteUrls.productsAndCategories.offerList.pendingApproval);
                          },
                        }
                      );
                    },
                  }
                );
              } else if (preOfferApprovalStatus === OfferApprovalStatus.REJECTED) {
                navigate(RouteUrls.productsAndCategories.offerList.rejected);
              } else {
                navigate(RouteUrls.productsAndCategories.offerList.pendingApproval);
              }
            }
          } else {
            if (isSubmitAction) {
              const requestBody = selectedRelatedProduct
                ? { product: selectedRelatedProduct.id }
                : {};
              submitPreOffer(
                { preOfferId: preOffer?.id, requestBody, requestConfig },
                {
                  onSuccess() {
                    navigate(RouteUrls.productsAndCategories.offerList.pendingApproval);
                  },
                }
              );
            }
          }
        },
      }
    );
  };

  return onSubmit;
}
