import { getProductOffersUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';

import { useAkinonQuery } from '../adapters/useAkinonQuery';
import { client } from '../client';

export function useProductOffersQuery({ queryOptions, onSucces, params } = {}) {
  const { data, isFetching, error } = useAkinonQuery({
    queryKey: [QueryKey.PRODUCT_OFFERS, params],
    async queryFn() {
      const response = await client.get(getProductOffersUrl, {
        params,
      });
      onSucces?.(response);

      return response;
    },
    ...queryOptions,
  });

  return {
    productOffers: data?.results,
    isFetchingProductOffers: isFetching,
    totalProductOffers: data?.count,
    productOffersError: error,
  };
}
