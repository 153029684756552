import { getRequestFiltersParams, getRequestPaginationParams } from '@common/index';
import { getProductRevisionsUrl } from '@constants/apiUrls';
import { HttpMethod } from '@constants/commontypes';
import { QueryKey } from '@constants/query';
import { useAkinonQuery } from '@services/api/adapters/useAkinonQuery';
import { client } from '@services/api/client';
import get from 'lodash/get';

/**
 * @param {Object} [args]
 * @param {Object} [args.params]
 * @param {import('@tanstack/react-query').UseQueryOptions} [args.queryOptions]
 * @param {(data: any) => void} [args.onSuccess]
 */
export function usePaginatedProductRevisionsQuery({
  queryOptions,
  params,
  onSuccess,
  pagination,
  filters,
} = {}) {
  const { data, isFetching, refetch } = useAkinonQuery({
    queryKey: [QueryKey.PRODUCT_REVISIONS, params, filters, pagination],
    async queryFn() {
      const method = filters?.filter_file ? 'post' : 'get';
      const allParams = {
        ...getRequestPaginationParams(pagination),
        ...getRequestFiltersParams(filters),
        ...params,
      };
      const formData = new FormData();
      const fileFilter = get(filters, 'filter_file');
      formData.append('filter_file', new Blob([fileFilter?.originFileObj]), fileFilter?.name);

      const response = await client[method](
        getProductRevisionsUrl,
        method === 'get' ? { params: allParams } : formData,
        method === 'post'
          ? {
              headers: {
                'Origin-Method': HttpMethod.GET,
              },
            }
          : null
      );
      onSuccess?.(response);
      return response;
    },
    ...queryOptions,
  });

  return {
    productRevisions: data?.results,
    totalProductRevisions: data?.count,
    isFetchingProductRevisions: isFetching,
    refetchProductRevisions: refetch,
  };
}
