import { useAttributeValuesQuery } from '@services/api/hooks/useAttributeValuesQuery';
import { Modal } from 'antd';
import find from 'lodash/find';
import flatMap from 'lodash/flatMap';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import reject from 'lodash/reject';
import uniq from 'lodash/uniq';
import React from 'react';
import { useTranslation } from 'react-i18next';

import CurrentAttributes from './CurrentAttributes';
import RequestedAttributes from './RequestedAttributes';

const RevisionDetailModal = ({
  title,
  isVisible,
  onCancel,
  revisionDetailModalInfo,
  attributes,
}) => {
  const { t } = useTranslation('ProductsAndCategories');
  const { currentAttributes, requestedAttributes } = revisionDetailModalInfo;
  const changedCurrentAttributeKeys = Object.entries(currentAttributes)
    .filter(([key, value]) => {
      return requestedAttributes[key] && requestedAttributes[key] !== value;
    })
    .map(([key]) => key);
  const changedRequestedAttributeKeys = Object.entries(requestedAttributes)
    .filter(([key, value]) => {
      return currentAttributes[key] !== value;
    })
    .map(([key]) => key);
  const attributeKeys = uniq([...changedCurrentAttributeKeys, ...changedRequestedAttributeKeys]);

  const filteredAttributes = attributes?.filter(
    (attribute) => attributeKeys.includes(attribute.key) && attribute.data_type === 'dropdown'
  );
  const attributeIds = uniq(map(filteredAttributes, 'id'));
  const attributeVals = reject(
    uniq(
      flatMap(filteredAttributes, (attribute) => [
        currentAttributes[attribute.key],
        requestedAttributes[attribute.key],
      ])
    ),
    isNil
  );

  const { attributeValues } = useAttributeValuesQuery({
    params: {
      attribute__in: attributeIds.join(','),
      value__in: attributeVals.join(','),
    },
    queryOptions: {
      enabled: !isEmpty(attributeVals) && !isEmpty(attributeIds),
    },
  });

  const _currentAttributes = attributeKeys.reduce((acc, key) => {
    const attribute = find(attributes, { key });
    const value = get(currentAttributes, key, '-');
    const attributeValue = find(attributeValues, { attribute: attribute?.id, value });
    acc[key] = get(attributeValue, 'label', String(value));
    return acc;
  }, {});

  const _requestedAttributes = attributeKeys.reduce((acc, key) => {
    const attribute = find(attributes, { key });
    const value = get(requestedAttributes, key, '-');
    const attributeValue = find(attributeValues, { attribute: attribute?.id, value });
    acc[key] = get(attributeValue, 'label', String(value));
    return acc;
  }, {});

  return (
    <div>
      <Modal open={isVisible} title={title} onCancel={onCancel} centered footer={null} width={800}>
        <div className="revision-detail-modal">
          <div className="revision-detail-modal__column">
            <div className="revision-detail-modal__column-title">
              {t('current_product_attributes')}
            </div>
            <hr />
            <CurrentAttributes
              attributes={attributes}
              currentAttributes={_currentAttributes}
              record={revisionDetailModalInfo.record}
            />
          </div>
          <div className="revision-detail-modal__column">
            <div className="revision-detail-modal__column-title">
              {t('requested_product_attributes')}
            </div>
            <hr />
            <RequestedAttributes
              attributes={attributes}
              requestedAttributes={_requestedAttributes}
              record={revisionDetailModalInfo.record}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default RevisionDetailModal;
