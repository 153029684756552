import './styles.scss';

import { Pagination, Select, Space } from 'antd';
import Text from 'antd/lib/typography/Text';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import React from 'react';

/**
 * @param {{ pagination: import('antd').PaginationProps, pageSizeLabel: string }} props
 */
const AkinonPagination = ({ pagination, setPagination, pageSizeLabel }) => {
  const handlePaginationChange = (page, pageSize) => {
    pagination.onChange?.(page, pageSize);
    setPagination({ current: page, pageSize });
  };

  const handlePageSizeChange = (value) => {
    pagination.onShowSizeChange?.(pagination.current, value);
    setPagination({ pageSize: value });
  };

  return (
    <Space size="large" className="akinon-pagination">
      {!isEmpty(pagination.pageSizeOptions) && (
        <Select
          value={pagination.pageSize}
          onChange={handlePageSizeChange}
          options={pagination.pageSizeOptions.map((pageSize) => ({ value: pageSize }))}
          className="akinon-pagination__select"
        />
      )}
      {pageSizeLabel && <Text className="akinon-pagination__page-size-label">{pageSizeLabel}</Text>}
      <Pagination
        className={clsx('akinon-pagination__pagination', pagination.className)}
        {...omit(pagination, 'className')}
        onChange={handlePaginationChange}
        simple
        responsive
      />
    </Space>
  );
};

export default AkinonPagination;
