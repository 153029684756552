import Link from '@components/Link';
import { RouteUrls } from '@constants/routeUrls';
import useStore from '@zustand-store/index';
import { setBreadcrumbsSelector } from '@zustand-store/selectors/app';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnmount } from 'react-use';

import { OfferStatus } from '../../common';

export function useBreadcrumbs({ offerStatus }) {
  const { t } = useTranslation('ProductsAndCategories');
  const setBreadcrumbs = useStore(setBreadcrumbsSelector);

  useEffect(() => {
    setBreadcrumbs([
      <Link
        key={RouteUrls.productsAndCategories.landing}
        to={RouteUrls.productsAndCategories.landing}
      >
        {t('products')}
      </Link>,
      <Link
        key={RouteUrls.productsAndCategories.offerList.landing}
        to={RouteUrls.productsAndCategories.offerList.landing}
      >
        {t('offer.actions')}
      </Link>,
      <Link
        key={
          OfferStatus.rejected.includes(offerStatus)
            ? RouteUrls.productsAndCategories.offerList.rejected
            : OfferStatus['pending-approval'].includes(offerStatus)
              ? RouteUrls.productsAndCategories.offerList.pendingApproval
              : RouteUrls.productsAndCategories.offerList.approved
        }
        to={
          OfferStatus.rejected.includes(offerStatus)
            ? RouteUrls.productsAndCategories.offerList.rejected
            : OfferStatus['pending-approval'].includes(offerStatus)
              ? RouteUrls.productsAndCategories.offerList.pendingApproval
              : RouteUrls.productsAndCategories.offerList.approved
        }
      >
        {OfferStatus.rejected.includes(offerStatus)
          ? t('offer.rejected')
          : OfferStatus['pending-approval'].includes(offerStatus)
            ? t('product.offer.waiting.confirmation')
            : t('approved')}
      </Link>,
      t('offer.actions'),
    ]);
  }, [t, offerStatus]);

  useUnmount(() => {
    setBreadcrumbs([]);
  });
}
