import './style.scss';

import { usePageFilters } from '@components/AkinonFilter/hooks/store/usePageFilters';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import { useUserRole } from '@utils/hooks/useUserRole';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ItemList } from '../../common/ItemList';
import { getColumns } from './common';
import { GeneralInfo } from './GeneralInfo';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { useDataSource } from './hooks/useDataSource';
import useFilterFields from './hooks/useFilterFields';
import { useMergedInfo } from './hooks/useMergedInfo';
import { usePageMeta } from './hooks/usePageMeta';

const ReconciliationDetail = () => {
  const { t } = useTranslation('Finance');

  useBreadcrumbs();
  usePageMeta();

  const { reconciliationId } = useParams();
  const { reconciliation } = useMergedInfo({ reconciliationId });
  const userRole = useUserRole();
  const columns = getColumns({ t, reconciliation, userRole });
  const filterFields = useFilterFields({ userRole });
  const filters = usePageFilters();
  const [pagination, setPagination] = usePagination();

  const { reconciliationItems, isLoading, total } = useDataSource({
    reconciliationId,
    pagination,
    filters,
  });

  return (
    <section className="reconciliation-detail">
      <GeneralInfo reconciliation={reconciliation} />
      <ItemList
        columns={columns}
        filterFields={filterFields}
        dataSource={reconciliationItems}
        loading={isLoading}
        total={total}
        paginationProps={{ pagination, setPagination }}
      />
    </section>
  );
};

export default ReconciliationDetail;
