import Box from '@components/utility/box';
import i18n from '@root/i18n';
import { dateFormatter } from '@utils/index';
import { Tag } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReconciliationStatusToColor } from '../ReconciliationListing/common';

export function GeneralInfo({ reconciliation = {} }) {
  const { t } = useTranslation('Finance');

  const {
    name,
    status,
    total_income,
    total_expanse,
    commission,
    maturity,
    total_commission,
    start_date,
    end_date,
    datasource,
  } = reconciliation;

  const formattedTotalCommission = new Intl.NumberFormat(i18n.language, {
    minimumFractionDigits: 2,
  }).format(total_commission);
  const formattedTotalIncome = new Intl.NumberFormat(i18n.language, {
    minimumFractionDigits: 2,
  }).format(total_income);
  const formattedTotalExpanse = new Intl.NumberFormat(i18n.language, {
    minimumFractionDigits: 2,
  }).format(total_expanse);

  return (
    <>
      <Box className="box-primary reconciliation-main-info-row">
        <div className="reconciliation-main-info">
          <div>{`${t('reconciliation_info')}: ${name}${datasource?.name ?? ''}`}</div>
          <div className="reconciliation-status-tag">
            <Tag color={ReconciliationStatusToColor[status]}>
              {t(`reconciliation_status.${status}`)}
            </Tag>
          </div>
        </div>
      </Box>

      <div className="card-box">
        <div className="card-box--item">
          <div className="card-box--header"> {t('total_sales_count')} </div>
          <div className="card-box--content"> {formattedTotalIncome} </div>
        </div>
        <div className="card-box--item">
          <div className="card-box--header"> {t('total_expanse_count')} </div>
          <div className="card-box--content"> {formattedTotalExpanse} </div>
        </div>
        <div className="card-box--item">
          <div className="card-box--header"> {t('unified_commision_rate')} </div>
          <div className="card-box--content"> {`%${Number(commission)}`} </div>
        </div>
        <div className="card-box--item">
          <div className="card-box--header"> {t('selling_commision')} </div>
          <div className="card-box--content"> {formattedTotalCommission} </div>
        </div>
        <div className="card-box--item">
          <div className="card-box--header"> {t('unified_maturity')} </div>
          <div className="card-box--content"> {maturity} </div>
        </div>
        <div className="card-box--item">
          <div className="card-box--header"> {t('reconciliation_start_date')} </div>
          <div className="card-box--content date">{dateFormatter(start_date)}</div>
        </div>
        <div className="card-box--item">
          <div className="card-box--header"> {t('reconciliation_end_date')} </div>
          <div className="card-box--content date">{dateFormatter(end_date)}</div>
        </div>
      </div>
    </>
  );
}
