import Link from '@components/Link';
import { RouteUrls } from '@constants/routeUrls';
import useStore from '@zustand-store/index';
import { setBreadcrumbsSelector } from '@zustand-store/selectors/app';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnmount } from 'react-use';

export function useBreadcrumbs() {
  const { t } = useTranslation('ProductsAndCategories');
  const setBreadcrumbs = useStore(setBreadcrumbsSelector);

  useEffect(() => {
    setBreadcrumbs([
      <Link
        key={RouteUrls.productsAndCategories.landing}
        to={RouteUrls.productsAndCategories.landing}
      >
        {t('products')}
      </Link>,
      <Link
        key={RouteUrls.productsAndCategories.offerList.landing}
        to={RouteUrls.productsAndCategories.offerList.landing}
      >
        {t('approved_offers')}
      </Link>,
      <Link
        key={RouteUrls.productsAndCategories.offerList.approved}
        to={RouteUrls.productsAndCategories.offerList.approved}
      >
        {t('approved')}
      </Link>,
      t('offer_detail'),
    ]);
  }, [t]);

  useUnmount(() => {
    setBreadcrumbs([]);
  });
}
