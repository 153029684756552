import './index.scss';

import AkinonCheckbox from '@components/AkinonCheckbox';
import AkinonForm from '@components/AkinonForm';
import AkinonFormItem from '@components/AkinonFormItem';
import AkinonInput from '@components/AkinonInput';
import AkinonSpin from '@components/AkinonSpin';
import AkinonTreeSelect from '@components/AkinonTreeSelect';
import Box from '@components/utility/box';
import { verticalFormItemLayout } from '@constants/layoutTypes';
import { useCategoryTreeData } from '@utils/hooks/useCategoryTreeData';
import clsx from 'clsx';
import first from 'lodash/first';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { BasicProductInfoFormItemKey } from './common';

/**
 * @param {{basicProductInfoForm: import('react-hook-form').UseFormReturn, }} props
 */
const BasicProductInfoForm = ({ basicProductInfoForm, dataSources, isDataSourcesLoading }) => {
  const { control } = basicProductInfoForm;
  const { t } = useTranslation('ProductsAndCategories');

  const { categoryTreeData, isCategoriesLoading } = useCategoryTreeData({
    generateTreeDataOptions: {
      valueKey: 'attribute_set',
      useItemId: true,
    },
  });

  const dataSource = first(dataSources?.results);

  return (
    <Box
      className="box-primary form-box"
      title={t('basic_product_info_title')}
      subtitle={t('basic_product_info_subtitle')}
    >
      <AkinonSpin
        spinning={isDataSourcesLoading}
        wrapperClassName={clsx('', {
          'restrict-created-products_spin': dataSource?.conf?.restrict_created_products,
        })}
      >
        <AkinonForm
          className="akn-form akn-form-v2"
          name="basicProductInfoForm"
          layout="vertical"
          colon
          {...verticalFormItemLayout}
        >
          <AkinonFormItem
            control={control}
            name={BasicProductInfoFormItemKey.NAME}
            id={BasicProductInfoFormItemKey.NAME}
            label={t('product_name')}
            requiredMarkPosition="right"
            requiredMarkContent={`* ${t('required')}`}
            required
          >
            <AkinonInput />
          </AkinonFormItem>
          <AkinonFormItem
            control={control}
            name={BasicProductInfoFormItemKey.BASE_CODE}
            label={t('basecode')}
            requiredMarkContent={`* ${t('required')}`}
            requiredMarkPosition="right"
            tooltip={{
              title: t('base_code_info'),
              style: { strokeColor: 'white' },
            }}
            required
          >
            <AkinonInput />
          </AkinonFormItem>

          <AkinonFormItem
            control={control}
            name={BasicProductInfoFormItemKey.CATEGORY}
            label={t('category')}
            requiredMarkContent={`* ${t('required')}`}
            requiredMarkPosition="right"
            tooltip={{
              title: t('category_info'),
            }}
            required
          >
            <AkinonTreeSelect
              loading={isCategoriesLoading}
              treeData={categoryTreeData}
              treeNodeFilterProp={'title'}
              allowClear
              showSearch
              treeDefaultExpandAll
              virtual={false}
              style={{ width: '100%' }}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            />
          </AkinonFormItem>

          {dataSource?.conf?.restrict_created_products && (
            <AkinonFormItem
              control={control}
              name={BasicProductInfoFormItemKey.RESTRICT_FOR_OTHERS}
              label={t('restrict_created_products')}
              tooltip={{
                title: t('restrict_created_product_tooltip'),
              }}
              labelCol={{
                xs: { span: 12 },
                sm: { span: 12 },
              }}
              wrapperCol={{
                xs: { span: 1 },
                sm: { span: 1 },
              }}
            >
              <AkinonCheckbox />
            </AkinonFormItem>
          )}
        </AkinonForm>
      </AkinonSpin>
    </Box>
  );
};

export default BasicProductInfoForm;
