import { getAnnouncementsUrl } from '@constants/apiUrls';
import { limitQuery } from '@constants/commontypes';
import { QueryKey } from '@constants/query';
import { useInfiniteQuery } from '@tanstack/react-query';
import flatten from 'lodash/flatten';

import { client } from '../client';

/**
 * @param {{ params: Object, queryOptions: import('@tanstack/react-query').UseInfiniteQueryOptions, onSuccess: Function }} args
 */

export function useGetAnnouncementsInfiniteQuery({ params, queryOptions, onSuccess } = {}) {
  const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage, refetch } =
    useInfiniteQuery({
      queryKey: [QueryKey.ANNOUNCEMENTS, params],
      queryFn: async ({ pageParam = 1 }) => {
        const response = await client.get(getAnnouncementsUrl, {
          params: {
            ...limitQuery,
            ...params,
            page: pageParam,
          },
        });
        onSuccess?.(response);

        return {
          results: response?.results,
          next: response?.next,
        };
      },
      getNextPageParam(lastPage) {
        if (!lastPage?.next) return undefined;
        const nextUrl = new URL(lastPage.next);
        const nextPage = nextUrl.searchParams.get('page');
        return nextPage ? Number(nextPage) : undefined;
      },
      ...queryOptions,
    });

  const _data = flatten(data?.pages?.map((page) => page.results ?? []));

  return {
    announcements: _data,
    isFetchingAnnouncements: isFetching || isFetchingNextPage,
    fetchAnnouncementsNextPage: fetchNextPage,
    hasAnnouncementsNextPage: hasNextPage,
    refetchAnnouncements: refetch,
  };
}
