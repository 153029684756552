import { getRequestFiltersParams, getRequestPaginationParams } from '@common/index';
import { QueryKey } from '@constants/query';
import { useAkinonQuery } from '@services/api/adapters/useAkinonQuery';
import { getCollections } from '@services/api/common';

export const useCollections = ({ pagination, filters }) => {
  const queryKey = [QueryKey.COLLECTIONS, pagination, filters];

  const queryFn = () =>
    getCollections({
      requestConfig: {
        params: {
          datasource__isnull: false,
          ...getRequestPaginationParams(pagination),
          ...getRequestFiltersParams(filters),
        },
      },
    });
  const { data, isFetching, isLoading, error, ...rest } = useAkinonQuery({ queryKey, queryFn });

  return {
    ...rest,
    collections: data?.results,
    total: data?.count,
    isLoading,
    isFetching,
    error,
  };
};
