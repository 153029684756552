import { WidgetType } from '@constants/commontypes';
import { zodResolver } from '@hookform/resolvers/zod';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import * as z from 'zod';

export const forbiddenKeys = ['SELLER_BARCODE', 'SELLER_BASECODE', 'SELLER_DESCRIPTION'];

export const FormKey = {
  NAME: 'name',
  CATEGORY: 'category',
  BASE_CODE: 'base_code',
  RESTRICT_FOR_OTHERS: 'restrict_for_others',
  PRODUCT_IMAGE: 'product_image',
  SKU: 'sku',
  STOCK: 'stock',
  PRICE: 'price',
  RETAIL_PRICE: 'retail_price',
  REFERENCE_PRICE: 'reference_price',
  LOWEST_PRICE: 'lowest_price',
  TAX_RATE: 'tax_rate',
  CURRENCY_TYPE: 'currency_type',
  SELLER_BARCODE: 'SELLER_BARCODE',
  SELLER_DESCRIPTION: 'attributes.SELLER_DESCRIPTION',
};

export const getIntegerSchema = ({ t }) =>
  z.coerce
    .string()
    .superRefine((value, ctx) => {
      if (trim(value) !== value) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t('white_space_validation_message'),
        });
        return z.NEVER;
      } else if (isEmpty(value)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t('formRule.required'),
        });
        return z.NEVER;
      }
    })
    .pipe(
      z.coerce
        .number({
          invalid_type_error: t('number.validation.message'),
        })
        .int({ message: t('integer.validation.message') })
        .nonnegative({ message: t('positive_validation_message') })
    );

export const getFloatSchema = ({ t }) =>
  z.coerce
    .string()
    .superRefine((value, ctx) => {
      if (trim(value) !== value) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t('white_space_validation_message'),
        });
      } else if (isEmpty(value)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t('formRule.required'),
        });
      }
    })
    .pipe(
      z.coerce
        .number({
          invalid_type_error: t('number.validation.message'),
        })
        .nonnegative({ message: t('positive_validation_message') })
    );

export const getOfferDetailFormResolver = ({ t, dynamicFields, canEditOffer, isSuperUser }) => {
  const integerSchema = getIntegerSchema({ t });
  const floatSchema = getFloatSchema({ t });
  const isDisabled = !canEditOffer || isSuperUser;

  return zodResolver(
    z.object({
      name: isSuperUser ? z.string().min(1) : z.string().optional(),
      category: isSuperUser ? z.string().min(1) : z.string().optional(),
      base_code: isSuperUser ? z.string().min(1) : z.string().optional(),
      restrict_for_others: z.boolean().optional(),
      sku: z.string().min(1),
      stock: isDisabled ? integerSchema.optional() : integerSchema,
      price: isDisabled ? floatSchema.optional() : floatSchema,
      retail_price: isDisabled ? floatSchema.optional() : floatSchema,
      reference_price: floatSchema.optional(),
      lowest_price: floatSchema.optional(),
      tax_rate: floatSchema,
      currency_type: z.string().min(1),
      SELLER_BARCODE: z.string().min(1),

      attributes: z.object({
        SELLER_DESCRIPTION: z
          .string()
          .min(1)
          .refine((value) => value !== '<p><br></p>' && Boolean(value), {
            message: t('this_field_is_required'),
          }),
        ...dynamicFields.reduce((acc, field) => {
          let schema;

          if (field.data_type === DataType.BOOL) {
            schema = field.required ? z.boolean() : z.boolean().optional();
          } else {
            schema = field.required ? z.string().min(1) : z.string().optional();
          }

          return {
            ...acc,
            [field.key]: schema,
          };
        }, {}),
      }),
    })
  );
};

export const DataType = {
  TEXT: 'text',
  AREA: 'area',
  DROPDOWN: 'dropdown',
  BOOL: 'bool',
};

export const DataTypeWidgetMap = {
  [DataType.TEXT]: WidgetType.INPUT,
  [DataType.AREA]: WidgetType.TEXTAREA,
  [DataType.DROPDOWN]: WidgetType.SELECT,
  [DataType.BOOL]: WidgetType.SWITCH,
};
